exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-acquia-js": () => import("./../../../src/pages/portfolio/acquia.js" /* webpackChunkName: "component---src-pages-portfolio-acquia-js" */),
  "component---src-pages-portfolio-gamerdna-js": () => import("./../../../src/pages/portfolio/gamerdna.js" /* webpackChunkName: "component---src-pages-portfolio-gamerdna-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-rinexus-js": () => import("./../../../src/pages/portfolio/rinexus.js" /* webpackChunkName: "component---src-pages-portfolio-rinexus-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */)
}

